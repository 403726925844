import React from 'react'
import TestimonialContact from '../components/sections/TestimonialContact';

const Team = () => {
    return ( 
        <TestimonialContact className="illustration-section-01"/>

     );
}
 
export default Team;