import React from 'react';
// import sections
import Hero2 from '../components/sections/Hero2';
// import FeaturesSplit from '../components/sections/FeaturesSplit';

// import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero2 className="illustration-section-01" />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      
      {/* <Cta split /> */}
    </>
  );
}

export default Home;